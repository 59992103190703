import React from 'react';

class LegalDisclosure extends React.Component {
    render() {
        return (
            <div className="et_pb_text_inner">
                <strong>Angaben gemäß § 5 TMG:</strong><br /><br />
                mVISE AG<br />
                Wanheimer Straße 66<br />
                40472 Düsseldorf<br />
                Deutschland
            <br /><br />
                <strong>Vertreten durch:</strong><br />
                Manfred Götz, Vorstand<br />
                Rainer Bastian, Vorstand
            <br /><br />
                <strong>Vorsitzender des Aufsichtsrats:</strong><br />
                Achim Plate<br /><br />
                <strong>Kontakt:</strong><br />
                Telefon: <a href="tel:+492117817800">+49 (211) 78 17 80-0</a><br />
                Telefax: <a href="fax:+4921178178078">+49 (211) 78 17 80-78</a><br />
                E-Mail: <a href="mailto:info@mvise.de">info@mvise.de</a><br /><br />
                <strong>Registereintrag:</strong><br />
                Eintragung im Handelsregister.<br />
                Registergericht: Amtsgericht Düsseldorf<br />
                Registernummer: HRB 76863<br /><br />
                <strong>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</strong><br />
                DE 212228930<br /><br />
                <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong><br />
                Manfred Götz<br /><br />
                {this.props.width === "xs" ? <h2>Allgemeine Geschäftsbedingungen der mVISE AG</h2> : <h1>Allgemeine Geschäftsbedingungen der mVISE AG</h1>}
                <strong>Haftung für Inhalte</strong><br /><br />
                Die auf den Web-Seiten abrufbaren Beiträge dienen nur der allgemeinen Information und nicht der Beratung in konkreten Fällen. Wir sind bemüht, für die Richtigkeit und Aktualität aller auf der Website enthaltenen Informationen und Daten gemäß § 7 Abs.1 TMG zu sorgen. Für die Korrektheit, Vollständigkeit, Aktualität oder Qualität der bereitgestellten Informationen und Daten wird jedoch keine Gewähr nach §§ 8 bis 10 TMG übernommen. Die Haftung für den Inhalt der abrufbaren Informationen wird ausgeschlossen, soweit es sich nicht um vorsätzliche oder grob fahrlässige Falschinformation handelt. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br /><br />
                <strong>Haftung für Links</strong><br /><br />
                Wir sind für den Inhalt von Webseiten, die über einen Hyperlink erreicht werden, nicht verantwortlich. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Wir machen uns die Inhalte dieser Internetseiten ausdrücklich nicht zu eigen und können deshalb für die inhaltliche Korrektheit, Vollständigkeit und Verfügbarkeit keine Gewähr leisten. Wir haben bei der erstmaligen Verknüpfung zwar den fremden Inhalt daraufhin überprüft, ob durch ihn eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Wir sind aber nicht dazu verpflichtet, die Inhalte, auf die wir unserem Angebot verweisen, ständig auf Veränderungen zu überprüfen, die eine Verantwortlichkeit neu begründen könnten. Erst wenn wir feststellen oder von anderen darauf hingewiesen werden, dass ein konkretes Angebot, zu dem wir einen Link bereitgestellt haben, eine zivil- oder strafrechtliche Verantwortlichkeit auslöst, werden wir den Verweis auf dieses Angebot aufheben, soweit uns dies technisch möglich und zumutbar ist.<br /><br />
                <strong>Urheberrecht</strong><br /><br />
                Das hier aufgeführte Toolkit basiert auf dem vom BSI definierten <a href="https://www.bsi.bund.de/DE/Themen/DigitaleGesellschaft/CloudComputing/Anforderungskatalog/Anforderungskatalog_node.html">Anforderungskatalog für Cloud Computing</a>. Die durch den Betreiber dieser Seite erstellten Inhalte und Werke auf diesen Webseiten unterliegen dem deutschen Urheberrecht. Sämtliche Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Kopien von diesen Seiten sind nur für den privaten Bereich gestattet, nicht jedoch für kommerzielle Zwecke.
        <br /><br /></div>
        )
    }
}
export default LegalDisclosure;