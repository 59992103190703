import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { lang as languageOptions } from '../config';
import languageBasedDetails from '../config/languages';
import LegalDisclosure from './legal_disclosure';
import PrivacyStatement from './privacy_statement';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class DialogPopup extends React.Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {

        return (
            <Grid container alignItems="center" >
                <Link
                    onClick={this.handleClickOpen}
                    className="bottomLink"
                    to={`/${this.props.name.toLowerCase()}`}
                >
                    {this.props.name}
                </Link>
                <Dialog
                    fullScreen={this.props.width === "xs" ? true : false}
                    open={this.props.url.includes(this.props.name.toLowerCase()) ? true : false}
                    TransitionComponent={Transition}
                    keepMounted
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {this.props.name.toUpperCase()}
                    </DialogTitle>
                    <DialogContent className="MuiTypography-root-180 MuiDialogContentText-root-341 MuiTypography-subheading-187 MuiTypography-colorTextSecondary-213">
                        {this.props.url === "/" + languageBasedDetails[languageOptions[1]].legal_disclosure_name.toLowerCase()
                            ? <LegalDisclosure width={this.props.width} />
                            : this.props.url === "/" + languageBasedDetails[languageOptions[0]].legal_disclosure_name.toLowerCase()
                                ? <LegalDisclosure width={this.props.width} />
                                : <PrivacyStatement width={this.props.width} />}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.props.history.goBack()}
                            component={Link}
                            to="/"
                        >
                            {languageBasedDetails[this.props.lang].dialog_close_button}
                        </Button >
                    </DialogActions>
                </Dialog>
            </Grid >
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.lang,
    }
}

export default connect(mapStateToProps)(DialogPopup);