import React, { Component } from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { updateLang, getDetailsFromLocalDB } from '../actions';
import { lang as languageOptions } from '../config';
import languageBasedDetails from '../config/languages';

import '../App.css';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 140,
    },
    formControl: {
        margin: theme.spacing.unit,
    },
    formControlMobile: {
        marginBottom: theme.spacing.unit,
        marginLeft: theme.spacing.unit,
    },
    selectEmpty: {
        color: 'white',
    },
});

class SelectLang extends Component {

    handleLangChange = event => {
        this.props.updateLang(event.target.value);
        if (this.props.types) {
            this.props.getDetailsFromLocalDB(this.props.types, event.target.value, false);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container>
                <Grid item>
                    {this.props.chapters.isLoading ? ""
                        : <form className={classes.root} autoComplete="off">
                            <FormControl className={this.props.width === "xs" ? classes.formControlMobile : classes.formControl}>
                                <Select
                                    value={this.props.lang ? this.props.lang : languageOptions[0]}
                                    onChange={this.handleLangChange.bind(this)}
                                    name="language"
                                    className={classes.selectEmpty}
                                    autoWidth
                                >
                                    <MenuItem value={languageOptions[0]} disabled={this.props.chapters.isLoading}>{languageBasedDetails[languageOptions[0]].language}</MenuItem>
                                    <MenuItem value={languageOptions[1]} disabled={this.props.chapters.isLoading}>{languageBasedDetails[languageOptions[1]].language}</MenuItem>
                                </Select>
                            </FormControl>
                        </form>}
                </Grid>
            </Grid>

        )
    }
}

function mapStateToProps(state) {
    return {
        lang: state.lang,
        types: state.type,
        chapters: state.chapters,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateLang, getDetailsFromLocalDB }, dispatch);
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(SelectLang);