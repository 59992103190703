import './App.css';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import localforage from 'localforage';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';

import SelectLang from './containers/select_lang';
import ChapterList from './containers/chapter_list';
import DetailsPaper from './containers/show_details';
import SelectType from './containers/select_types';
import BottomNavigation from './containers/bottom_navigation';
import { appBarTitle } from './config';
import { loadAllChapters, getChaptersFromLocalDB, getDetailsFromLocalDB, updateTypes } from './actions';
import { lang as languageOptions, colorBlue } from './config';
import withWidth from '@material-ui/core/withWidth';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarMobile: {
    paddingRight: 24, // keep right padding when drawer closed
    minHeight: theme.spacing.unit * 10,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    minHeight: theme.spacing.unit * 10,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colorBlue,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    zIndex: 5,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: theme.spacing.unit * 1.5,
    marginRight: theme.spacing.unit * 2,
  },
  menuButtonHidden: {
    display: 'none',
  },
  h6: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  contentPC: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: `calc(100vh - ${theme.spacing.unit * 2.5}px)`,
    overflow: 'auto',
  },
  contentMobile: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: `calc(100vh - ${theme.spacing.unit * 7}px)`,
    overflow: 'auto',
  },
  'content-left': {
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left-open-PC': {
    marginLeft: 240,
  },
  'contentShift-left-closed-pc': {
    marginLeft: theme.spacing.unit * 9,
  },
  'contentShift-left-closed-mobile': {
    marginLeft: theme.spacing.unit * 7,
  },
  'contentShift-left-open-mobile': {
    marginLeft: theme.spacing.unit * 7,
  },
  titleMobile: {
    marginTop: theme.spacing.unit,
  },
  title: {
    padding: theme.spacing.unit,
  },
  appBarMobile: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: colorBlue,
    minHeight: theme.spacing.unit * 10,
  },
  appBarShiftMobile: {
    width: `calc(100% - ${theme.spacing.unit * 8}px)`,
    zIndex: theme.zIndex.drawer - 1,
  },
  appBarSpacerMobile: {
    minHeight: theme.spacing.unit * 10,
  },
});

class App extends Component {
  state = {
    open: this.props.width === "xs" ? false : true,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentDidMount = () => {
    let type = this.props.match.params.type,
      defaultLang = languageOptions[0],
      chapter = this.props.match.params.chapter,
      id = parseInt(this.props.match.params.id) - 1;
    localforage.length().then((numberOfKeys) => {
      if (numberOfKeys === 0) {
        this.props.loadAllChapters().then(() => {
        })
      } else {
        if (id > -1) {
          this.props.getChaptersFromLocalDB(type, defaultLang, false);
          this.props.getDetailsFromLocalDB(type, defaultLang, false, chapter, id, true, this.props.match.params);
          this.props.updateTypes(type);
        } else if (type) {
          this.props.getChaptersFromLocalDB(type, defaultLang, false);
          this.props.getDetailsFromLocalDB(type, defaultLang, false, chapter, null, false, this.props.match.params);
          this.props.updateTypes(type);
        }
      }
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <AppBar
            position="absolute"
            className={classNames(this.props.width === "xs" ?
              classes.appBarMobile :
              classes.appBar,
              this.state.open &&
              (
                this.props.width === "xs" ?
                  classes.appBarShiftMobile :
                  classes.appBarShift
              )
            )}
          >
            <Toolbar disableGutters={!this.state.open} className={this.props.width === "xs" ? classes.toolbarMobile : classes.toolbar}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(
                  classes.menuButton,
                  this.state.open && classes.menuButtonHidden,
                )}
              >
                <MenuIcon />
              </IconButton>
              {this.props.width === "xs" ?
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="inherit" noWrap spacing={16} className={!this.props.chapters.isLoading ? classes.titleMobile : ""}>
                      {appBarTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item>
                      <SelectType width={this.props.width} />
                    </Grid>
                    <Grid item>
                      <SelectLang width={this.props.width} />
                    </Grid>
                  </Grid>
                </Grid>
                :
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                      {appBarTitle}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} container align="flex-end" justify="flex-end" spacing={16}>
                    <Grid item>
                      <SelectType width={this.props.width} />
                    </Grid>
                    <Grid item>
                      <SelectLang width={this.props.width} />
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Toolbar>
          </AppBar>
          {this.props.width === "xs" ?
            <BottomNavigation
              width={this.props.width}
              selectedType={this.props.match.params.type}
              selectedIDInfos={this.props.match.params.id}
              selectedChapter={this.props.match.params.chapter}
              url={this.props.match.url}
              history={this.props.history}
            />
            :
            <BottomNavigation
              width={this.props.width}
              selectedType={this.props.match.params.type}
              selectedIDInfos={this.props.match.params.id}
              selectedChapter={this.props.match.params.chapter}
              url={this.props.match.url}
              history={this.props.history}
            />
          }
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
            }}
            open={this.state.open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            <div className={classes.root}>
              <ChapterList width={drawerWidth} match={this.props.match} />
            </div>
          </Drawer>
          <main className={classNames(this.props.width === "xs" ? classes.contentMobile : classes.contentPC, classes['content-left'], {
            [classes.contentShift]: this.props.width === "xs" ? false : this.state.open,
            [classes['contentShift-left-open-PC']]: this.props.width === "xs" ? false : this.state.open,
            [classes['contentShift-left-open-mobile']]: this.props.width === "xs" ? this.state.open : false,
            [classes['contentShift-left-closed-mobile']]: this.props.width === "xs" ? !this.state.open : false,
            [classes['contentShift-left-closed-pc']]: this.props.width === "xs" ? false : !this.state.open

          })}>
            <div className={this.props.width === "xs" ? classes.appBarSpacerMobile : classes.appBarSpacer} />
            <Grid item xs={12}>
              <DetailsPaper width={this.props.width} />
            </Grid>
          </main>
        </div>

      </React.Fragment >
    );
  }
}

function mapStateToProps(state) {
  return {
    chapters: state.chapters,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ loadAllChapters, getChaptersFromLocalDB, getDetailsFromLocalDB, updateTypes }, dispatch);
}

const enhance = compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(App);