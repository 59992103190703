import localforage from 'localforage';
import axios from 'axios';

import { API_KEY, url, types, lang } from '../config';

export const UPDATE_LANG = 'update_lang';
export const UPDATE_TYPES = 'update_types';
export const GET_DETAILS = 'get_details';
export const GET_REQUIREMENTS = 'get_requirements'
export const SET_CLICKED_CHAPTER = 'set_clicked_chapter';
export const UPDATE_IDS = 'update_ids';
export const RESET_ON_TYPE_CHANGE = 'reset_on_type_change';
export const CHAPTERS_ARE_LOADING = 'chapters_are_loading';
export const FIRST_LOAD = 'first_load';
export const LOAD_ALL_CHAPTERS = 'load_all_chapters';
export const CLOSE_CHAPTERS = 'close_chapters';
export const OPEN_CHAPTER = 'open_chapter';
export const DISPLAY_ID = 'display_id';

export function updateLang(lang) {
    return {
        type: UPDATE_LANG,
        payload: lang,
    };
}

export function updateTypes(type) {
    return {
        type: UPDATE_TYPES,
        payload: type,
    };
}

export function openChapter(currentChapterIndex, showDetails) {
    return {
        type: OPEN_CHAPTER,
        payload: {
            currentChapterIndex,
            showDetails,
        }
    }
}

export function displayID(showDetails = false, selectedIDInfos = 0, additionalInfoNames) {
    return {
        type: DISPLAY_ID,
        payload: {
            showDetails,
            selectedIDInfos,
            additionalInfoNames,
        }
    }
}

export function resetOnTypeChange(reset) {
    return {
        type: RESET_ON_TYPE_CHANGE,
        payload: reset,
    };
}

export function chaptersAreLoading(isLoading) {
    return {
        type: CHAPTERS_ARE_LOADING,
        payload: isLoading,
    }
}

function forwardChaptersToReducer(obj) {
    return {
        type: GET_REQUIREMENTS,
        chapter: obj.chapter,
        requirements: obj.requirements,
    }
}


export async function getChaptersFromLocalDB(type, lang, typeChanged) {
    let obj = {};
    return (dispatch) => {
        localforage.getItem(`chapter`)
            .then((arr) => {
                obj = { ...obj, chapter: arr }
                localforage.getItem(`requirements`)
                    .then((arr) => {
                        obj = { ...obj, requirements: arr }
                        dispatch(getDetailsFromLocalDB(type, lang, typeChanged));
                        dispatch(forwardChaptersToReducer(obj));
                    })
            })

    }
}

export async function saveChaptersInLocalDB(data) {
    for (var i = 0; i < Object.keys(data).length; i++) {
        localforage.setItem(`${Object.keys(data)[i]}`, data[Object.keys(data)[i]])
    }
}

export async function getDetailsFromLocalDB(type, lang, typeChanged, chapter, id, showDetails, params) {
    var wholeTypeSpecificData = localforage.getItem(`${type}_${lang}`)
    if (wholeTypeSpecificData) {
        return (dispatch) => {
            dispatch(forwardDetailsToReducer(wholeTypeSpecificData))
                .then(() => {
                    if (chapter) {
                        dispatch(forwardDetailsToReducer({
                            chapter,
                            id,
                            showDetails,
                            params,
                        }));
                    }
                    if (typeChanged)
                        dispatch(closeChapters())
                })
        }
    }
}

function forwardDetailsToReducer(obj) {
    return {
        type: GET_DETAILS,
        payload: obj
    }
}

export async function saveDetailsInLocalDB(dataArr, type, lang) {
    let keys = Object.keys(dataArr[1].data).sort();
    let wholeTypeSpecificData = {};

    switch (type) {
        case 'IaaS':
            if (lang === 'de') {
                keys.map((item) => {
                    return wholeTypeSpecificData = { ...wholeTypeSpecificData, [item]: dataArr[1].data[item].de };
                });
                localforage.setItem(`IaaS_de`, { wholeTypeSpecificData });
            } else if (lang === 'en') {
                keys.map((item) => {
                    return wholeTypeSpecificData = { ...wholeTypeSpecificData, [item]: dataArr[1].data[item].en };
                });
                localforage.setItem(`IaaS_en`, { wholeTypeSpecificData });
            }
            break;
        case 'PaaS':
            Object.keys(dataArr[1].data).sort().map((item) => {
                return dataArr[1].data[item][lang].map((item2) => {
                    if (item2.service_type === 'SaaS' || item2.service_type === 'PaaS') {
                        if (typeof wholeTypeSpecificData[item] === 'undefined')
                            wholeTypeSpecificData = { ...wholeTypeSpecificData, [item]: [] };
                        if (typeof wholeTypeSpecificData[item] === 'object') {
                            wholeTypeSpecificData[item].push(item2)
                        }
                    }
                    return 0;
                })
            })
            if (lang === 'de') {
                localforage.setItem(`PaaS_de`, { wholeTypeSpecificData });
            } else if (lang === 'en') {
                localforage.setItem(`PaaS_en`, { wholeTypeSpecificData });
            }
            break;
        case 'SaaS':
            Object.keys(dataArr[1].data).sort().map((item) => {
                return dataArr[1].data[item][lang].map((item2) => {
                    if (item2.service_type === 'SaaS') {
                        if (typeof wholeTypeSpecificData[item] === 'undefined')
                            wholeTypeSpecificData = { ...wholeTypeSpecificData, [item]: [] };
                        if (typeof wholeTypeSpecificData[item] === 'object') {
                            wholeTypeSpecificData[item].push(item2)
                        }
                    }
                    return 0;
                })
            })
            if (lang === 'de') {
                localforage.setItem(`SaaS_de`, { wholeTypeSpecificData });
            } else if (lang === 'en') {
                localforage.setItem(`SaaS_en`, { wholeTypeSpecificData });
            }
            break;
        default:
    }
}


export function setClickedChapter(selectedChapterIndex, selectedChapterName) {
    return {
        type: SET_CLICKED_CHAPTER,
        payload: {
            selectedChapterIndex,
            selectedChapterName,
        },
    }
}

export async function loadAllChapters() {
    let reqArray = [];
    reqArray.push(axios.get(`${url}`, {
        headers: {
            'x-api-key': API_KEY,
        },
    }))

    reqArray.push(axios.get(`${url}type/`, {
        headers: {
            'x-api-key': API_KEY,
        },
    }))

    Promise.all(reqArray).then((values) => {
        saveDetailsInLocalDB(values, 'IaaS', 'de');
        saveDetailsInLocalDB(values, 'IaaS', 'en');
        saveDetailsInLocalDB(values, 'PaaS', 'de');
        saveDetailsInLocalDB(values, 'PaaS', 'en');
        saveDetailsInLocalDB(values, 'SaaS', 'de');
        saveDetailsInLocalDB(values, 'SaaS', 'en');
        saveChaptersInLocalDB(values[0].data);
    })

    return (dispatch) => {
        dispatch(chaptersAreLoading(true));
        localforage.length().then((numberOfKeys) => {
            if (numberOfKeys === types.length * lang.length + 2) {
                dispatch(chaptersAreLoading(false));
            }

        })
        Promise.all(reqArray).then(() => {
            dispatch(chaptersAreLoading(false));
        })
    }
}

export function closeChapters(typeChanged) {
    return {
        type: CLOSE_CHAPTERS,
        payload: typeChanged
    }
}