import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import DialogPopup from './dialog_popup';
import languageBasedDetails from '../config/languages';

const styles = theme => ({
    root: {
        width: "100%",
        boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.14)",
        position: "absolute",
        padding: 4,
        zIndex: 100,
        bottom: 0,
    },
});

class SimpleBottomNavigation extends React.Component {


    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;

        return (
            <div
                className={classes.root}
            >
                <Grid container justify="flex-end" spacing={8}>
                    <Grid item>
                        <DialogPopup
                            name={languageBasedDetails[this.props.lang].privacy_statement_name}
                            url={this.props.url}
                            width={this.props.width}
                            history={this.props.history}
                        />
                    </Grid>
                    <Grid item>
                        <DialogPopup
                            name={languageBasedDetails[this.props.lang].legal_disclosure_name}
                            url={this.props.url}
                            width={this.props.width}
                            history={this.props.history}
                        />
                    </Grid>
                </Grid>
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.lang,
    }
}
const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps)
)

SimpleBottomNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default enhance(SimpleBottomNavigation);