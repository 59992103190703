import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { bindActionCreators } from 'redux';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import IDList from './id_list';

import '../App.css';

import { updateTypes, getChaptersFromLocalDB, getDetailsFromLocalDB, openChapter, setClickedChapter } from '../actions';
import { colorBlue } from '../config';

const styles = theme => ({
    avatar: {
        fontSize: 13,
        backgroundColor: colorBlue,
    },
    chapter: {
        padding: 0,
        marginLeft: -10,
        width: 240,
    },
});

class ChapterList extends Component {

    handleClickChapter = (indexOfSelected, chapter) => {
        console.log(indexOfSelected, chapter);
        this.props.setClickedChapter(indexOfSelected, chapter);
        this.manageListOpenClose(indexOfSelected);
    };

    manageListOpenClose(indexOfSelected) {
        let open = this.props.chapters.chaptersOpened;
        if (this.props.chapters.currentChapterIndex !== indexOfSelected) {
            this.props.openChapter(indexOfSelected, false);
        }

        if (open[indexOfSelected] === false || typeof open[indexOfSelected] === 'undefined') {
            this.props.chapters.typeSpecificChapters.map((item, x) => {
                return open[x] = false;
            })
            open[indexOfSelected] = open[indexOfSelected] !== true;
        } else {
            this.props.chapters.typeSpecificChapters.map(() => {
                return open[indexOfSelected] = open[indexOfSelected] !== true;
            })
        }
    }

    handleTypesChange = event => {
        this.props.updateTypes(event.target.value);
        this.props.getChaptersFromLocalDB(event.target.value, this.props.lang, false)
        this.props.getDetailsFromLocalDB(event.target.value, this.props.lang, false);
    }

    render() {
        var self = this;
        const { classes } = this.props;
        return (
            <div>
                {this.props.chapters.typeSpecificChapters && this.props.chapters.allChapters ?
                    this.props.chapters.allChapters.length > 0 ?
                        this.props.chapters.typeSpecificChapters.map((item, i) => {
                            if (self.props.chapters.typeSpecificRequirements[i][item].length) {
                                return <List key={item} component="nav" className={classes.chapter} onClick={self.handleClickChapter.bind(this, i, item)}>
                                    <ListItem button component={Link} to={`/${this.props.type}/${item}`}>
                                        <ListItemIcon>
                                            <Avatar className={classes.avatar}>{item}</Avatar>
                                        </ListItemIcon>
                                        <ListItemText disableTypography primary={
                                            <Typography style={{ fontWeight: 700, fontSize: 17 }}>
                                                {item}
                                            </Typography>}
                                        />
                                        {this.props.chapters.chaptersOpened[i] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    {this.props.chapters.chaptersOpened[i] ? <IDList match={this.props.match} /> : ""}
                                    <Divider />
                                </List>
                            }
                            return "";
                        }) : ""
                    : ""}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        type: state.type,
        lang: state.lang,
        chapters: state.chapters,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateTypes, getChaptersFromLocalDB, getDetailsFromLocalDB, openChapter, setClickedChapter }, dispatch);
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(ChapterList);