import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import '../App.css';

import languageBasedDetails from '../config/languages';

const styles = theme => ({
    panel: {
        marginTop: theme.spacing.unit * 2,
    },
    flexEnd: {
        maxWidth: '100%',
        height: `calc(100vh - ${theme.spacing.unit * 34}px)`,
    },
    firstPanelFixPosition: {
        paddingRight: 0,
    },
    fontSizeBig: {
        fontSize: '1.1rem',
    },
})

class TitleText extends Component {
    state = {
        title: "",
        content: ""
    }

    componentDidMount() {
        switch (this.props.type) {
            case "short":
                this.setState({
                    caption: languageBasedDetails[this.props.language].short_title,
                    text: this.props.item.short
                })
                break;
            case "reference":
                this.setState({
                    caption: languageBasedDetails[this.props.language].reference_title,
                    text: this.prepareReferences(this.props.item.references)
                })
                break;
            default:
                break;
        }
    }

    prepareReferences(text) {
        return text;
    }

    render() {
        var { classes } = this.props;
        return this.props.variant === "firstInPanel" ?
            <React.Fragment>
                <ExpansionPanelSummary className={classes.firstPanelFixPosition}>
                    <Typography variant="h5" className={this.props.width === "xs" ? classes.fontSizeBig : ""} >
                        {languageBasedDetails[this.props.language][`${this.props.type}_title`]}
                        <Divider />
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ display: 'block' }}>
                    <Typography>
                        {this.props.item[this.props.type]}
                    </Typography>
                </ExpansionPanelDetails>
            </React.Fragment>
            : this.props.variant === "subsequentInPanel" ?
                <React.Fragment>
                    <ExpansionPanelDetails>
                        <Typography variant="h5" className={this.props.width === "xs" ? classes.fontSizeBig : ""}>
                            {languageBasedDetails[this.props.language][`${this.props.type}_title`]}
                            <Divider />
                        </Typography>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails style={{ display: 'block' }}>
                        <Typography>
                            {this.props.item[this.props.type]}
                        </Typography>
                    </ExpansionPanelDetails>
                </React.Fragment>
                : "";
    }
}

export default withStyles(styles)(TitleText);