import {
    GET_REQUIREMENTS,
    CLOSE_CHAPTERS,
    GET_DETAILS,
    SET_CLICKED_CHAPTER,
    RESET_ON_TYPE_CHANGE,
    CHAPTERS_ARE_LOADING,
    FIRST_LOAD,
    OPEN_CHAPTER,
    DISPLAY_ID,
} from '../actions';

export default function (state = {}, action) {
    switch (action.type) {
        case GET_REQUIREMENTS:
            let allChapters;
            let requirements;
            if (action.requirements && action.chapter) {
                allChapters = action.chapter;
                requirements = action.requirements;
                allChapters.sort();
                requirements.sort();
            }
            return { ...state, allChapters, requirements };
        case GET_DETAILS:
            if (action.payload) {
                let chaptersOpened,
                    clickedChapterIndex,
                    clickedChapterName,
                    currentChapterIndex,
                    showDetails,
                    selectedIDInfos;
                if (typeof action.payload.chapter === 'string') {
                    state.typeSpecificRequirements.map((item) => {
                        if (state.typeSpecificChapters.includes(action.payload.params.chapter) && item[action.payload.params.chapter]) {
                            let id = parseInt(action.payload.params.id)
                            chaptersOpened = state.chaptersOpened ? state.chaptersOpened : [];
                            clickedChapterIndex = state.typeSpecificChapters.indexOf(action.payload.chapter);
                            clickedChapterName = action.payload.chapter;
                            currentChapterIndex = state.typeSpecificChapters.indexOf(action.payload.chapter);
                            if (item[action.payload.params.chapter].length >= id - 1) {
                                showDetails = action.payload.showDetails;
                                selectedIDInfos = action.payload.id;
                                chaptersOpened[clickedChapterIndex] = true;
                            }
                        }
                        return 0;
                    })
                    return { ...state, chaptersOpened, clickedChapterIndex, clickedChapterName, currentChapterIndex, showDetails, selectedIDInfos };
                } else if (action.payload.wholeTypeSpecificData) {
                    let typeSpecificChapters = [], typeSpecificRequirements = {}, chaptersOpened = state.chaptersOpened ? state.chaptersOpened : [];
                    Object.keys(action.payload.wholeTypeSpecificData).sort().map((item, i) => {
                        return action.payload.wholeTypeSpecificData[Object.keys(action.payload.wholeTypeSpecificData).sort()[i]].length > 0 ?
                            typeSpecificChapters.push(Object.keys(action.payload.wholeTypeSpecificData).sort()[i]) : null
                    });
                    typeSpecificRequirements = typeSpecificChapters.map((item, i) => {
                        return { ...typeSpecificRequirements, [typeSpecificChapters[i]]: action.payload.data ? action.payload.data[item] : action.payload.wholeTypeSpecificData[item] }
                    });
                    return { ...state, typeSpecificChapters, chaptersOpened, typeSpecificRequirements, };
                }
            }
            return { ...state }
        case OPEN_CHAPTER:
            return {
                ...state,
                currentChapterIndex: action.payload.currentChapterIndex,
                showDetails: action.payload.showDetails,
            }
        case DISPLAY_ID:
            return {
                ...state,
                showDetails: action.payload.showDetails,
                selectedIDInfos: action.payload.selectedIDInfos,
                additionalInfoNames: action.payload.additionalInfoNames,
            }
        case SET_CLICKED_CHAPTER:
            return {
                ...state,
                clickedChapterName: action.payload.selectedChapterName,
                clickedChapterIndex: action.payload.selectedChapterIndex,
            }
        case RESET_ON_TYPE_CHANGE:
            return {
                ...state,
                showDetails: action.payload,
            }
        case CHAPTERS_ARE_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case FIRST_LOAD:
            return state;
        case CLOSE_CHAPTERS:
            let chaptersOpened = [];
            state.typeSpecificChapters.map(() => {
                return chaptersOpened.push(false)
            });
            return {
                ...state,
                chaptersOpened,
            }
        default:
            return state;
    }
}