module.exports = {
    de: {
        language: "DE",
        typesPlaceholder: "Typ auswählen",
        noTypeSelctedOnStartupText: "Bitte einen Typ auswählen",
        short_title: "Zusammenfassung",
        basic_title: "Basisanforderung",
        optional_title: "Optionale, weitergehende Anforderungen",
        advanced_title: "Ergänzende Informationen zur Basisanforderung",
        references_title: "Referenzen",
        chapter_info: "Kapitel: ",
        refresh_dialog: "Bitte die Seite neu laden",
        dialog_close_button: "schlieẞen", //großes ẞ damit es angezeigt wird
        privacy_statement_name: "Datenschutz",
        legal_disclosure_name: "Impressum",
    },
    en: {
        language: "EN",
        typesPlaceholder: "Select Type",
        noTypeSelctedOnStartupText: "Please select a type",
        basic_title: "Basic requirement",
        optional_title: "Description of additional requirements",
        advanced_title: "Supplementary information for the basic requirement",
        references_title: "References",
        chapter_info: "Chapter: ",
        refresh_dialog: "Please Refresh the page",
        dialog_close_button: "close",
        privacy_statement_name: "Privacy Statement",
        legal_disclosure_name: "Legal Disclosure",
    },
}