import { combineReducers } from 'redux';
import LangReducer from './reducer_lang';
import TypeReducer from './reducer_types';
import ChapterReducer from './reducer_chapters';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
    lang: LangReducer,
    form: formReducer,
    type: TypeReducer,
    chapters: ChapterReducer,
});

export default rootReducer;