import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { bindActionCreators } from 'redux';
import '../App.css';

import { openChapter, displayID } from '../actions';
import { colorHover } from '../config';
import { Avatar } from '@material-ui/core';

const styles = theme => ({
    avatar: {
        fontSize: 13,
    },
    selected: {
        backgroundColor: colorHover + '!important'
    }
});

class IDList extends Component {

    componentDidMount = () => {
        if (this.props.match.params.id) {
            this.props.chapters.typeSpecificRequirements.map((item) => {
                if (this.props.chapters.typeSpecificChapters.includes(this.props.match.params.chapter) && item[this.props.match.params.chapter]) {
                    if (item[this.props.match.params.chapter].length >= this.props.match.params.id - 1) {
                        this.handleClickID(parseInt(this.props.match.params.id) - 1, this.props.match.params.chapter, false);
                    }
                }
                return 0;
            })
        }
    }

    handleClickID = (selected, chapterName, triggerOpenClose) => {
        let open = this.props.chapters.chaptersOpened;
        let propNames = {};
        if (chapterName) {
            let info = this.props.chapters.typeSpecificRequirements[this.props.chapters.currentChapterIndex][chapterName][selected];
            let additionalInfonames = Object.getOwnPropertyNames(info)
                .map((item) => {
                    return propNames = {
                        ...propNames,
                        [item]: item.replace(new RegExp('_', 'g'), ' '),
                    }
                });
            additionalInfonames = additionalInfonames[additionalInfonames.length - 1];
            this.props.openChapter(this.props.chapters.currentChapterIndex);
            this.props.displayID(true, selected, additionalInfonames);
        } else {
            this.props.openChapter(this.props.currentChapterIndex);
            this.props.displayID(true, selected);
        }
        if (chapterName && triggerOpenClose) {
            open[this.props.chapters.typeSpecificChapters.indexOf(chapterName)] = open[this.props.chapters.typeSpecificChapters.indexOf(chapterName)] !== true;
        }
    };

    render() {
        let open = this.props.chapters.chaptersOpened;
        let allids = [], self = this;
        let id = this.props.chapters.typeSpecificRequirements[this.props.chapters.clickedChapterIndex][this.props.chapters.clickedChapterName];
        id = _.sortBy(id, 'ID')
        let { classes } = this.props;
        id.map((item, x) => {
            return allids.push(
                <Collapse key={x} in={open[this.props.chapters.typeSpecificChapters.indexOf(item.ID.split("-")[0])]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            className={x === self.props.match.params.id - 1 ? classes.selected : ""}
                            component={Link}
                            to={`/${this.props.type}/${item.ID}`}
                            onClick={self.handleClickID.bind(self, x, item.ID.split("-")[0], true)}
                        >
                            <ListItemIcon>
                                <Avatar className={classes.avatar}>{item.ID.split("-")[1]}</Avatar>
                            </ListItemIcon>
                            <ListItemText inset primary={item.ID} />
                        </ListItem>
                    </List>
                </Collapse>
            )
        })
        return (
            <div>
                {allids}
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        type: state.type,
        lang: state.lang,
        chapters: state.chapters,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ displayID, openChapter }, dispatch);
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(IDList);