import '../App.css';
import React, { Component } from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

import { updateTypes, getChaptersFromLocalDB, resetOnTypeChange } from '../actions';
import { lang as languageOptions, types } from '../config';
import languageBasedDetails from '../config/languages';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 140,
    },
    formControl: {
        margin: theme.spacing.unit,
    },
    formControlMobile: {
        marginBottom: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    selectEmpty: {
        color: 'white',
    },
});

class SelectTypes extends Component {

    handleTypesChange = event => {
        this.props.updateTypes(event.target.value);
        this.props.getChaptersFromLocalDB(event.target.value, this.props.lang, true);
        this.props.resetOnTypeChange();
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container>
                <Grid item>
                    {this.props.chapters.isLoading ? ""
                        : <form className={classes.root} autoComplete="off">
                            <FormControl className={this.props.width === "xs" ? classes.formControlMobile : classes.formControl}>
                                <Select
                                    value={this.props.type === "" ? this.props.lang === languageOptions[1] ? "noneEN" : "noneDE" : this.props.type}
                                    onChange={this.handleTypesChange.bind(this)}
                                    name="types"
                                    className={classes.selectEmpty}
                                >
                                    <MenuItem value={this.props.lang === languageOptions[1] ? "noneEN" : "noneDE"} disabled>
                                        {languageBasedDetails[this.props.lang].typesPlaceholder}
                                    </MenuItem>
                                    <MenuItem value={types[0]} disabled={this.props.chapters.isLoading} component={Link} to={`/${types[0]}`}>{types[0]}</MenuItem>
                                    <MenuItem value={types[1]} disabled={this.props.chapters.isLoading} component={Link} to={`/${types[1]}`}>{types[1]}</MenuItem>
                                    <MenuItem value={types[2]} disabled={this.props.chapters.isLoading} component={Link} to={`/${types[2]}`}>{types[2]}</MenuItem>
                                </Select>
                            </FormControl>
                        </form>}
                </Grid>
            </Grid>

        )
    }
}

function mapStateToProps(state) {
    return {
        chapters: state.chapters,
        type: state.type,
        lang: state.lang
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ updateTypes, getChaptersFromLocalDB, resetOnTypeChange }, dispatch);
}

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)

export default enhance(SelectTypes);