import '../App.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import _ from 'lodash';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';

import TitleText from './expansion_panels';
import languageBasedDetails from '../config/languages';
import { getDetailsFromLocalDB, getChaptersFromLocalDB, updateTypes, resetOnTypeChange } from '../actions';

const styles = theme => ({
    panel: {
        marginTop: theme.spacing.unit * 2,
    },
    flexEnd: {
        maxWidth: '100%',
        height: `calc(100vh - ${theme.spacing.unit * 34}px)`,
    },
    firstPanelFixPosition: {
        paddingRight: 0,
    },
    fontSizeBig: {
        fontSize: '1.1rem',
    },
})

class DetailsPaper extends Component {

    render() {
        let infoToBeExcluded = ["title", "ID", "basic", "short", "lang", "service_type", "optional", "advanced"];
        var { classes } = this.props;
        if (this.props.chapters.showDetails && this.props.chapters.typeSpecificRequirements) {
            let arr = this.props.chapters.typeSpecificRequirements;
            let selectedChapter = this.props.chapters.currentChapterIndex;
            let selectedIDInfos = _.sortBy(arr[selectedChapter][this.props.chapters.typeSpecificChapters[selectedChapter]], 'ID')[this.props.chapters.selectedIDInfos];
            var displayedInfo = <Grid item xs={12}>
                <Grid item xs={12}>
                    <Typography variant={this.props.width === "xs" ? "h5" : "h4"} gutterBottom>{selectedIDInfos.ID + ":"}</Typography>
                    <Typography variant="h5" gutterBottom className={this.props.width === "xs" ? classes.fontSizeBig : ""}>
                        {selectedIDInfos.title}
                    </Typography>
                    <Divider /> <br />
                </Grid>
                {selectedIDInfos.short ?
                    <Grid container>
                        <Grid item xs={12} className={classes.panel}>
                            <ExpansionPanel expanded>
                                <TitleText language={this.props.lang} type={"short"} item={selectedIDInfos} variant={"firstInPanel"} />
                                <TitleText language={this.props.lang} type={"basic"} item={selectedIDInfos} variant={"subsequentInPanel"} />
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs={12} className={classes.panel}>
                            <ExpansionPanel expanded>
                                <TitleText language={this.props.lang} type={"basic"} item={selectedIDInfos} variant={"firstInPanel"} />
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                }
                {
                    selectedIDInfos.optional ?
                        <Grid container>
                            <Grid item xs={12} className={classes.panel}>
                                <ExpansionPanel>
                                    <TitleText language={this.props.lang} type={"optional"} item={selectedIDInfos} variant={"firstInPanel"} />
                                </ExpansionPanel>
                            </Grid>
                        </Grid>
                        : ""
                }
                {
                    selectedIDInfos.advanced ?
                        <Grid container >
                            <Grid item xs={12} className={classes.panel}>
                                <ExpansionPanel>
                                    <TitleText language={this.props.lang} type={"advanced"} item={selectedIDInfos} variant={"firstInPanel"} />
                                </ExpansionPanel>
                            </Grid>
                        </Grid>
                        : ""
                }
                {
                    this.props.chapters.additionalInfoNames ?
                        <Grid container >
                            <Grid item xs={12} className={classes.panel}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h5" className={this.props.width === "xs" ? classes.fontSizeBig : ""}>
                                            {languageBasedDetails[this.props.lang].references_title}
                                            <Divider />
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails style={{ display: 'block' }}>
                                        <Typography>
                                            {Object.keys(this.props.chapters.additionalInfoNames).sort().map(item => {
                                                if (!infoToBeExcluded.includes(item))
                                                    return typeof selectedIDInfos[item] === 'undefined' ?
                                                        "" :
                                                        this.props.chapters.additionalInfoNames[item] + ": " + selectedIDInfos[item] + "\n"
                                                else
                                                    return ""
                                            })}
                                        </Typography>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        </Grid>
                        : ""
                }
            </Grid >
        }
        if (this.props.match.params.type && !this.props.chapters.showDetails && this.props.chapters.isLoading === false && this.props.type === "") {
            window.location.reload();
        }

        return (
            !this.props.chapters.showDetails && this.props.type === "" ?
                <Grid container className={classes.flexEnd} justify="center" alignItems="center">
                    <Grid item>
                        {
                            this.props.chapters.isLoading ?
                                <CircularProgress className={classes.progress} size={150} />
                                :
                                !this.props.match.params.type ?
                                    <Typography variant="h3">
                                        {languageBasedDetails[this.props.lang].noTypeSelctedOnStartupText}
                                    </Typography>
                                    : ""
                        }
                    </Grid>
                </Grid>
                : this.props.chapters.showDetails ?
                    <div>
                        {displayedInfo}
                    </div>
                    : this.props.isLoading ?
                        <CircularProgress className={classes.progress} size={150} />
                        : Object.keys(this.props.match.params).length > 0 ?
                            <Grid className={classes.flexEnd} alignItems="center" justify="center" container>
                                <Grid item>
                                    <Typography variant={this.props.width === "xs" ? "h4" : "h3"}>
                                        {
                                            this.props.chapters.typeSpecificChapters ?
                                                this.props.chapters.typeSpecificChapters.includes(this.props.match.params.chapter) ?
                                                    languageBasedDetails[this.props.lang].chapter_info + this.props.match.params.chapter
                                                    :
                                                    ""
                                                :
                                                ""}
                                    </Typography>
                                </Grid>
                            </Grid>
                            :
                            ""

        )
    }
}

function mapStateToProps(state) {
    return {
        type: state.type,
        lang: state.lang,
        chapters: state.chapters,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDetailsFromLocalDB, getChaptersFromLocalDB, updateTypes, resetOnTypeChange }, dispatch);
}

export default compose(
    withStyles(styles),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(DetailsPaper);